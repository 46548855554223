import { useState, useEffect, useRef, ChangeEvent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OverlayTrigger, Popover } from "react-bootstrap"
import { InputRatingStars } from "./InputRatingStars"
import { useAppDispatch } from "../../app/hooks"

interface ReviewFieldInputProps {
  index: number | boolean
  label?: string
  infoText: string
  inputName: string
  reviewType: "shop" | "product"
  forceNotShowError?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  mainRating: boolean | undefined
  autocompleteValue?: any
  autocompleteMethod?: any
}

export const ReviewFieldInput: React.FC<ReviewFieldInputProps> = ({
  index,
  label,
  infoText,
  inputName,
  reviewType,
  forceNotShowError,
  onChange,
  mainRating = false,
  autocompleteValue = undefined,
  autocompleteMethod = undefined,
}) => {
  const [manualInput, setManualInput] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const hasAutocompletedRef = useRef(false)

  const containerClass = mainRating
    ? "d-flex flex-column flex-lg-row align-items-center my-1 my-lg-3 justify-content-center justify-content-lg-between border-bottom pb-3"
    : "d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between"

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setManualInput(true)
    onChange(e)
  }

  useEffect(() => {
    if (
      autocompleteValue &&
      !manualInput &&
      autocompleteMethod &&
      !hasAutocompletedRef.current
    ) {
      hasAutocompletedRef.current = true
      dispatch(autocompleteMethod(autocompleteValue))
    }
  }, [autocompleteValue])

  return (
    <div className={`w-100 my-3 ${containerClass}`}>
      {label && (
        <div
          className={
            mainRating
              ? "font-23 font-bolder lightgray-txt mb-3 mb-lg-0"
              : "lightgray-txt font-16 font-semi-bolder mb-3 mb-lg-0"
          }
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover>
                <Popover.Body>{infoText}</Popover.Body>
              </Popover>
            }
          >
            <span
              className="d-none d-lg-inline me-2 badge badge-gray font-12"
              style={{
                padding: "2px",
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
            >
              <FontAwesomeIcon icon={["fas", "info"]} />
            </span>
          </OverlayTrigger>
          <span className={mainRating ? "me-3 mb-3" : ""}>{label}</span>
          {label === "Assistenza clienti" && (
            <small className="orange-color font-12 ms-1">(Facoltativo)</small>
          )}
        </div>
      )}
      <InputRatingStars
        inputName={inputName}
        reviewType={reviewType}
        customStarClass={
          reviewType === "shop" && !mainRating
            ? "lg-star-small-container"
            : "star-big-container"
        }
        customAverageRatingClass={reviewType === "product" ? "xs-font-16" : ""}
        customContainerClass={
          mainRating
            ? "justify-content-center mt-0 mt-lg-3"
            : "justify-content-left"
        }
        forceNotShowError={forceNotShowError}
        onChange={handleChange}
      />
    </div>
  )
}
