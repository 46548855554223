import React, { useState } from "react"
import { Accordion } from "react-bootstrap"
import { useAppSelector } from "../../app/hooks"
import { selectSendReviewFormData } from "../../pages/send_review/sendReviewSlice"
import { ProductReviewItem } from "./ProductReviewItem"
import { InvitationOrderProduct } from "../../models/base/invitationOrder.interface"
import { Shop } from "../../models/base/shop.interface"

interface StepTwoProps {
  products: InvitationOrderProduct[]
  shop?: Shop
  orderId?: number
}

export const StepTwo: React.FC<StepTwoProps> = ({ products, shop, orderId }) => {
  const [activeKeys, setActiveKeys] = useState<string[]>([products[0].id])
  const formData = useAppSelector(selectSendReviewFormData)

  function setKeys(key: string, confirmAction?: boolean) {
    if (confirmAction) {
      // if the set is from the confirm product review button
      const keyIndex = products.map((p) => p.id).indexOf(key)

      if (keyIndex !== -1) {
        // Find the next element after the selected key
        const nextIndex = keyIndex + 1
        const nextKey = products.map((p) => p.id)[nextIndex]

        // If the next element is present, add it to the array
        if (nextKey !== undefined) {
          setActiveKeys([nextKey])
        } else {
          setActiveKeys([])
        }
      }
    } else {
      setActiveKeys((prevKeys) => {
        if (prevKeys.includes(key)) {
          // if present, remove it
          return prevKeys.filter((existingKey) => existingKey !== key)
        } else {
          // if not present, return only the actual key
          return [key]
        }
      })
    }
  }

  return (
    <Accordion
      activeKey={activeKeys}
      flush
      className="d-flex flex-column align-items-center justify-content-center"
    >
      {products.map((product, index) => {
        const isReviewed = Object.keys(formData.confirmedProducts).some(
          (key) => key == product.id.toString(),
        )
        return (
          <ProductReviewItem
            key={index}
            index={index}
            product={product}
            isReviewed={isReviewed}
            setKeys={setKeys}
            shop={shop}
            orderId={orderId}
          />
        )
      })}
    </Accordion>
  )
}
