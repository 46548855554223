import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ChangeEvent, FormEvent, useState, useEffect } from "react"
import { Form } from "react-bootstrap"
import { Link, useSearchParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useCheckUserMutation } from "../../app/services/base/users"
import { addToast } from "../toasts/toastsSlice"
import {
  setGuestModal,
  setLoginModal,
  setRegistrationModal,
  setEmailInserted,
  setUserType,
} from "../modals/modalsSlice"
import { FacebookAuth } from "./FacebookAuth"
import { GoogleAuth } from "./GoogleAuth"
import { InvitationOrder } from "../../models/base/invitationOrder.interface"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { UserCheckResponseProps } from "../../app/services/base/users"

interface UserCheckProps {
  isInModal: boolean
  invitationOrder?: InvitationOrder
}

export const UserCheck: React.FC<UserCheckProps> = ({
  isInModal = false,
  invitationOrder,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [formData, setFormData] = useState<{ email: string }>({
    email:
      invitationOrder && invitationOrder.customer_is_registered
        ? invitationOrder.customer_email
        : "",
  })
  const [showEmailField, setShowEmailField] = useState<boolean>(false)
  const [validated, setValidated] = useState<boolean>(false)
  const [checkUser, { error }] = useCheckUserMutation()
  const [userCheckResponse, setUserCheckResponse] = useState<
    UserCheckResponseProps | undefined
  >(undefined)

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget
    setFormData({ ...formData, [name]: value })
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    // debugger
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      try {
        const checkUserResp = await checkUser(formData.email).unwrap()
        const response =
          typeof checkUserResp.success === "string"
            ? checkUserResp.success.toLowerCase()
            : ""
        // dispatch(setUser(checkUserResp))
        switch (response) {
          case "email_registered":
            dispatch(setEmailInserted(formData.email))
            dispatch(setUserType("email"))
            if (isInModal) {
              dispatch(setLoginModal(true))
              break
            }
            navigate("/areariservata/login")
            break
          case "facebook_registered":
            dispatch(setEmailInserted(""))
            dispatch(setUserType("facebook"))
            if (isInModal) {
              dispatch(setLoginModal(true))
              break
            }
            navigate("/areariservata/login")
            break
          case "google_registered":
            dispatch(setEmailInserted(""))
            dispatch(setUserType("google"))
            if (isInModal) {
              dispatch(setLoginModal(true))
              break
            }
            navigate("/areariservata/login")
            break
          case "not_registered":
            dispatch(setEmailInserted(formData.email))
            dispatch(setUserType(undefined))
            if (isInModal) {
              dispatch(setRegistrationModal(true))
              break
            }
            navigate("/registrazione/cliente")
            break
          case "b2b_registered":
            dispatch(setEmailInserted(undefined))
            dispatch(setUserType(undefined))
            break
          default:
            dispatch(setEmailInserted(undefined))
            dispatch(setUserType(undefined))
            dispatch(
              addToast({
                message: "Ops! Si è verificato un errore imprevisto.",
                type: "danger",
              }),
            )
            setValidated(true)
        }
        setValidated(false)
        setUserCheckResponse(checkUserResp as UserCheckResponseProps)
      } catch (err: any) {
        setUserCheckResponse(err.data as UserCheckResponseProps)
      }
    }
  }

  return (
    <>
      {isInModal && (
        <p className="font-20 lightgray-txt text-center m-0 mb-4">
          Per garantire alla nostra community recensioni sempre verificate e
          autentiche:
        </p>
      )}
      <h6 className="font-33 dark-color font-bolder m-0 mt-2 mt-lg-0 mb-1">
        Accedi o registrati
      </h6>
      <p className="font-14 lightgray-txt text-center m-0 mb-4">
        e condividi le tue esperienze con la community
      </p>
      <Form
        className={`login-form myform reg_container ${
          isInModal ? "w-100" : "w-75"
        }`}
        noValidate
        onSubmit={handleSubmit}
      >
        <p className="lightgray-txt font-14 m-0 mb-3 text-center">
          Continua con
        </p>
        <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center w-100 mb-3 gap-lg-1 mt-3">
          <div id="hoauthWidgetyw0" className="hoauthWidget d-flex w-100">
            <FacebookAuth type="Login" showName={true} />
            <GoogleAuth type="Login" showName={true} />
          </div>
        </div>
        <p className="lightgray-txt font-14 m-0 mb-3 text-center">oppure</p>
        {showEmailField ? (
          <Form.Group className="d-flex flex-column w-100 mb-3">
            <Form.Control
              type="email"
              className="form-control form-control-custom form-control-gray my-1"
              autoComplete="off"
              placeholder="Digita qui la tua email"
              required
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {userCheckResponse?.success &&
            userCheckResponse.success == "b2b_registered" ? (
              <Form.Control.Feedback type="invalid" className="d-block">
                Questa email è associata ad un account B2B.
                <br />
                <Link
                  to="https://business.eshoppingadvisor.com/areariservata/login"
                  target="_blank"
                  className="simple-blue-link orange-hover"
                >
                  Accedi qui
                </Link>
              </Form.Control.Feedback>
            ) : validated ? (
              <Form.Control.Feedback type="invalid" className="d-block">
                Inserisci una email valida
              </Form.Control.Feedback>
            ) : null}
            <button
              className="btn btn-animation btn-action btn-gradient-orange d-flex align-items-center justify-content-center font-semi-bolder w-100 my-3 form"
              style={{ height: "50px" }}
              type="submit"
              // onClick={() => handleSubmit}
            >
              Continua
            </button>
            {userCheckResponse && !userCheckResponse.success && (
              <div className="text-danger">
                {userCheckResponse && userCheckResponse.message
                  ? userCheckResponse.message
                  : "Ops! Si è verificato un errore imprevisto."}
              </div>
            )}
          </Form.Group>
        ) : (
          <button
            onClick={() => setShowEmailField(true)}
            className="btn btn-outline-gray d-flex align-items-center justify-content-center w-100 p-3 mb-3 mb-lg-0 me-1 font-light"
            style={{ height: "50px" }}
            type="button"
          >
            <FontAwesomeIcon icon={faEnvelope} className="font-24" />
            <span className="ms-2">Continua con email</span>
          </button>
        )}
        {invitationOrder != undefined && (
          <button
            className="btn btn-outline-animation btn-outline-gradient-blue d-flex justify-content-center align-items-center font-14 w-100 my-3 p-3"
            onClick={() => dispatch(setGuestModal(true))}
            style={{ height: "50px" }}
          >
            Continua senza registrarti
            <FontAwesomeIcon
              icon={["fas", "arrow-right-long"]}
              className="align-middle thin-icon font-18 ms-2"
            />
          </button>
        )}
      </Form>
      <p className="font-14 lightgray-txt text-center my-3 mx-0 mx-lg-5">
        Confermo di aver preso visione dell'
        <Link
          to="/it/informativa-privacy"
          className="simple-blue-link orange-hover"
          target="_blank"
        >
          informativa sulla privacy
        </Link>
        , della{" "}
        <Link
          to="/it/informativa-cookies"
          className="simple-blue-link orange-hover"
          target="_blank"
        >
          cookies policy
        </Link>{" "}
        e delle{" "}
        <Link
          to="/it/manleva"
          className="simple-blue-link orange-hover"
          target="_blank"
        >
          condizioni d'uso
        </Link>{" "}
        delle recensioni e acconsento all'invio della vostra newsletter.
      </p>
    </>
  )
}
