import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FormEvent, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
} from "react-bootstrap"
import { useGetReviewsTagsQuery } from "../../../app/services/esaScraper/reviews"
import { Shop } from "../../../models/base/shop.interface"
import { skipToken } from "@reduxjs/toolkit/query"
import loader from "../../../assets/images/loader.webp"
import { capitalizeFirstLetter } from "../../../utils/stringUtils"

interface EShopFiltersProps {
  shop: Shop
  orderBy: { label: string; value: string }
  handleOrderChange: (order: { label: string; value: string }) => void
  enableOrderBy: boolean
  enableTags: boolean
  searchQuery?: string
  handleSearchQueryChange: (selectedTag?: string) => void
  isProductTab: boolean
}

export const EShopFilters: React.FC<EShopFiltersProps> = ({
  shop,
  orderBy,
  handleOrderChange,
  enableOrderBy,
  enableTags,
  searchQuery,
  handleSearchQueryChange,
  isProductTab,
}) => {
  const orders = [
    { label: "Più recenti", value: "publication_datetime-desc" },
    { label: "Più datate", value: "publication_datetime-asc" },
    { label: "Media più alta", value: "review_rating-desc" },
    { label: "Media più bassa", value: "review_rating-asc" },
  ]

  const [searchText, setSearchText] = useState<string>(
    searchQuery ? searchQuery : "",
  )

  const { data: tags, isFetching } = useGetReviewsTagsQuery(
    shop.id && enableTags
      ? { esaDomainId: shop.id, review_prod: isProductTab ? true : false }
      : skipToken,
  )

  function handleSubmit(e: FormEvent) {
    e.preventDefault()
    handleSearchQueryChange(searchText)
  }

  return (
    <form
      className="d-block w-100 searchTagForm"
      id="reviews-filter-tag"
      onSubmit={handleSubmit}
    >
      <div className="search-group d-flex flex-column flex-lg-row justify-content-lg-between align-items-start align-items-lg-center mb-4 mb-lg-3">
        <div className="d-flex flex-column mb-4 mb-lg-0 me-lg-5">
          <h6 className="font-semi-bolder dark-color mb-3">
            Ricerca nelle recensioni
          </h6>
          <div className="input-group search-bar-gray">
            <input
              className="form-control focus-gray font-14 lightgray-txt"
              placeholder="Digita una parola chiave per cercare"
              aria-label="Digita una parola chiave per cercare"
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
            <button
              type="submit"
              className="input-group-text btn btn-gradient-orange"
            >
              <FontAwesomeIcon
                icon={["fas", "magnifying-glass"]}
                className="font-18"
              />
            </button>
          </div>
        </div>
        {enableOrderBy && (
          <div className="d-flex flex-lg-column justify-content-between align-items-center align-items-lg-start">
            <h6 className="font-semi-bolder dark-color m-0 me-5 mb-lg-3">
              Ordina per
            </h6>
            <Dropdown bsPrefix="dropdown eshop-dropdown flex-grow-1">
              <DropdownToggle
                as={"button"}
                bsPrefix="btn"
                type="button"
                className="d-flex justify-content-between align-items-center font-14 w-100"
              >
                {orderBy.label}
                <FontAwesomeIcon
                  icon={["fas", "chevron-down"]}
                  className="font-20"
                />
              </DropdownToggle>
              <DropdownMenu as={"ul"} className="eshop-dropdown-menu w-100 p-0">
                {orders.map((order) => (
                  <DropdownItem
                    key={order.value}
                    as={"li"}
                    className="eshop-dropdown-item font-semi-bolder"
                    onClick={() => handleOrderChange(order)}
                  >
                    {order.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
      </div>
      {enableTags && (
        <div className="d-flex flex-column">
          <Nav as={"ul"} className="filters flex-row mb-4">
            {isFetching ? (
              <span className="tagLoader d-flex justify-content-center align-items-center">
                <img className="spin height-25 mx-3" src={loader} />
                Caricamento tag in corso...
              </span>
            ) : (
              <>
                {searchQuery && (
                  <NavItem
                    as={"li"}
                    className="py-1 me-3 remove-filter-key"
                    onClick={() => {
                      setSearchText("")
                      handleSearchQueryChange!(undefined)
                    }}
                  >
                    <NavLink className="filter-key-item font-14">
                      Tutte le recensioni
                    </NavLink>
                  </NavItem>
                )}
                {tags &&
                  tags.review_kw.length > 0 &&
                  tags.review_kw.slice(0, 6).map((keyword) => (
                    <NavItem
                      key={keyword.key}
                      as={"li"}
                      className="py-1 me-3"
                      onClick={() =>
                        handleSearchQueryChange!(
                          keyword.key + keyword.terminations[0].end,
                        )
                      }
                    >
                      <NavLink
                        className={`filter-key-item font-14 ${
                          searchQuery ==
                          keyword.key + keyword.terminations[0].end
                            ? "active"
                            : ""
                        }`}
                      >
                        {capitalizeFirstLetter(
                          keyword.key + keyword.terminations[0].end,
                        )}
                      </NavLink>
                    </NavItem>
                  ))}
              </>
            )}
          </Nav>
        </div>
      )}
    </form>
  )
}
