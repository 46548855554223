import { ReviewUserUnion } from "../models/base/reviewUserUnion.interface"
import { EsaScraperReview } from "../models/esaScraper/esaScraperReview.interface"

// Le Reviews le prendo sempre da EsaScraper perchè ricevo facilmente i dati che mi servono
// Quindi utilizzo questa funzione per mappare i due oggetti, per utilizzare nei componenti sempre e solo un'interfaccia
// Consiglio di ricontrollare questa funzione, voi conoscete meglio i campi
export const esaScraperReviewToReview = (
  esaScraperReview: EsaScraperReview,
): ReviewUserUnion => {
  const name = ((!esaScraperReview.esa_user_data?.name &&  !esaScraperReview.esa_user_data?.surname && !esaScraperReview.nickname)  ? "Anonimo" : esaScraperReview.esa_user_data?.name  || "Utente anonimo");
  return {
    review: {
      id: esaScraperReview.esa_review_id || 0,
      eshop_id: esaScraperReview.esa_domain_id || 0,
      user_id: esaScraperReview.esa_user_data?.id || undefined,
      order_id: esaScraperReview.order_id || undefined,
      overall_rating: esaScraperReview.review_rating?.toString() || "0.0",
      details_rating:
        JSON.stringify(esaScraperReview.detailed_rating) ||
        buildDetailsRating(esaScraperReview),
      title: esaScraperReview.title || "",
      description: esaScraperReview.description || "",
      insert_date: esaScraperReview.insert_date || "",
      publication_date: esaScraperReview.publication_datetime || "",
      update_date: undefined, // TODO Quetsa data non esiste all'interno della review proveniente da esaScraper
      delete_date: esaScraperReview.deletion_datetime || undefined,
      certified: esaScraperReview.certified || 0,
      status: esaScraperReview.status || 0,
      source: esaScraperReview.import_source || undefined,
      images: esaScraperReview.images || undefined,
      video_url: esaScraperReview.videos || undefined,
      replies: esaScraperReview.replies,
      pendingUpdate: esaScraperReview.modified
        ? { updateDate: esaScraperReview.publication_datetime }
        : { updateDate: undefined },
      reports: esaScraperReview.review_report_data
        ? Object.values(esaScraperReview.review_report_data).map(
            (esaReport) => ({
              consumer_id: esaReport.user_id,
              status: esaReport.status,
              insert_date: esaReport.insert_date,
              reason: esaReport.reason,
            }),
          )
        : undefined,
      is_prod_review: (esaScraperReview.id_prod != undefined && esaScraperReview.id_prod != null)  ? 1 : 0,
      prod_review:
        esaScraperReview.products != "None"
          ? parseProducts(esaScraperReview.products)[0]?.map(
              (product: any) => ({
                id_prod: product.id,
                name_prod: product.name,
                img_prod: product.img_prod,
                url_prod: product.url_prod, // CHECK
              }),
            )
          : undefined,
      liked: esaScraperReview.liked || false,
    },
    user: {
      name: name,
      surname: esaScraperReview.esa_user_data?.surname || undefined,
      nickname: esaScraperReview.esa_user_data?.nickname || undefined,
      reviews_number: esaScraperReview.esa_user_data?.reviews_count || 0,
      career_level: esaScraperReview.esa_user_data?.carrer_level || "",
    },
  }
}

const buildDetailsRating = (esaScraperReview: EsaScraperReview) => {
  let detailsRating: any = {}

  const addRating = (ratingName: string, ratingValue?: number): void => {
    if (ratingValue !== undefined) {
      detailsRating[ratingName] = ratingValue
    }
  }

  addRating("Chiarezza sito web", esaScraperReview.facilita_esecuzione_ordine)
  addRating(
    "Valutazione prodotto/servizio",
    esaScraperReview.qualita_prodotto_servizio,
  )
  addRating("Servizio di pagamento", esaScraperReview.sistema_pagamento)
  addRating("Consegna/Reso", esaScraperReview.velocita_spedizione)
  addRating("Assistenza clienti", esaScraperReview.assistenza_clienti)
  addRating(
    "Lo consiglieresti a qualcuno?",
    esaScraperReview.esperienza_generale_acquisto,
  )

  const jsonDetailsRating = JSON.stringify(detailsRating)

  return jsonDetailsRating
}

const parseProducts = (productsJson: string) => {
  let correctedString = productsJson.trim()
  if (correctedString.startsWith("(") && correctedString.endsWith(")")) {
    correctedString = correctedString.slice(1, -1)
  }

  correctedString = correctedString.replace(/,\s*$/, "")

  try {
    return JSON.parse(correctedString)
  } catch (error) {
    // console.error("Failed to parse JSON:", error)
    return []
  }
}
