import { baseApi } from "./baseApi"
import { Encryption } from "../../../utils/encryption"

// Type for the trace event data
interface TraceEventData {
    event: string
    data: any
}

export const tracerApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
    sendTraceEvent: build.mutation<void, TraceEventData>({
        query: (eventData) => {
        const encryptData = new Encryption()
        const data = {
            ...eventData.data,
            timestamp: new Date().toISOString(),
            event: eventData.event,
        }
        return {
            url: "trk",
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: {
                tr: encryptData.encrypt(
                JSON.stringify(data),
                import.meta.env.VITE_EVENT_TRACER_API_KEY,
                ),
            },
            }
        },
        }),
    }),
})

export const { useSendTraceEventMutation } = tracerApi
