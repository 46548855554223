import React from "react"
import star5 from "../../../assets/icons/star_5.webp"
import star4 from "../../../assets/icons/star_4.webp"
import star3 from "../../../assets/icons/star_3.webp"
import star2 from "../../../assets/icons/star_2.webp"
import star1 from "../../../assets/icons/star_1.webp"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  selectProgressRatings,
  setSelectedProgressRatings,
} from "./ratingProgressBarSlice"
import { EsaScraperReviewStats } from "../../../models/esaScraper/esaScraperReviewStats.interface"

interface RatingProgressBarProps {
  starNumber: 5 | 4 | 3 | 2 | 1
  reviewStats?: EsaScraperReviewStats
}

export const RatingProgressBar: React.FC<RatingProgressBarProps> = ({
  starNumber,
  reviewStats,
}) => {
  const dispatch = useAppDispatch()
  const selectedProgressRatings = useAppSelector(selectProgressRatings)
  const starImages = {
    5: star5,
    4: star4,
    3: star3,
    2: star2,
    1: star1,
  }
  const selectedStarImage = starImages[starNumber]

  const mapDataValues = {
    5: "great",
    4: "good",
    3: "average",
    2: "poor",
    1: "bad",
  }

  const ratingValue =
    reviewStats &&
    reviewStats.review_data[
      mapDataValues[starNumber] as keyof EsaScraperReviewStats["review_data"]
    ]
      ? Number(
          (
            ((reviewStats.review_data[
              mapDataValues[
                starNumber
              ] as keyof EsaScraperReviewStats["review_data"]
            ] || 0) /
              (reviewStats.total_review || 1)) *
            100
          ).toFixed(1),
        )
      : 0
  console.log("total: ", reviewStats)

  return (
    <div className="d-flex eshop-rating-detail-item cursor-pointer justify-content-between align-items-center mb-4">
      <input
        className="form-check-input lg-check-small me-3 custom-checkbox"
        type="checkbox"
        name="progressCheckbox"
        checked={selectedProgressRatings.some((r) => r === starNumber)}
        value={starNumber}
        onChange={(e) =>
          dispatch(setSelectedProgressRatings(Number(e.currentTarget.value)))
        }
      />
      <div
        className={`rating-number-eshop font-semi-bolder font-16 text-center me-1 star-${starNumber}`}
        style={{ width: "12px" }}
      >
        {starNumber}
      </div>
      <img
        src={selectedStarImage}
        alt="star"
        className="me-3"
        style={{ width: "16px", marginBottom: "3px" }}
      />
      <div
        className="progress review-progress flex-grow-1 me-3"
        role="progressbar"
        aria-label="RatingProgressBar"
      >
        <div
          className={`progress-bar review-progress-bar star-progress star-${starNumber}`}
          aria-valuenow={ratingValue}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width: `${ratingValue}%` }}
        ></div>
      </div>
      <div
        className="font-semi-bolder dark-color text-end"
        style={{ width: "55px" }}
      >
        <div
          className="font-semi-bolder dark-color text-end"
          style={{ width: "55px" }}
        >
          {`${ratingValue}%`}
        </div>
      </div>
    </div>
  )
}
