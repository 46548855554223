import React from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import notFoundCss from "../not_found/notFound.css?inline"

import { ReactNode } from "react"

interface pageProps {
  title: string
  content: ReactNode
  showHomeBtn?: boolean
}

export const Page: React.FC<pageProps> = ({
  title,
  content,
  showHomeBtn = true,
}) => {
  return (
    <section className="error-page user-area-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="w-container utente">
              <div className="row">
                <div className="col-12 text-center">
                  <h3>{title}</h3>
                  {content}
                  {showHomeBtn && (
                    <span className="back-to-home">
                      <FontAwesomeIcon icon={["fas", "caret-right"]} />
                      Vai alla <Link to="/">Home Page</Link>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{notFoundCss}</style>
    </section>
  )
}
