import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ChangeEvent, FormEvent, useState } from "react"
import { Form } from "react-bootstrap"
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { useDispatch } from "react-redux"
import { useLoginMutation } from "../../app/services/base/users"
import { setUser } from "./authSlice"
import { addToast } from "../toasts/toastsSlice"
import {
  selectLoginModal,
  selectRegistrationModal,
  setGuestModal,
  setLoginModal,
  setRegistrationModal,
  selectEmailInserted,
  selectUserType,
} from "../modals/modalsSlice"
import { useAppSelector } from "../../app/hooks"
import { FacebookAuth } from "./FacebookAuth"
import { GoogleAuth } from "./GoogleAuth"
import { InvitationOrder } from "../../models/base/invitationOrder.interface"

interface LoginAuthProps {
  isInModal: boolean
  invitationOrder?: InvitationOrder
}

export const LoginAuth: React.FC<LoginAuthProps> = ({
  isInModal,
  invitationOrder,
}) => {
  const location = useLocation()
  const from = location.state?.from?.pathname || "/"
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loginModal = useAppSelector(selectLoginModal)
  const emailInserted = useAppSelector(selectEmailInserted)
  const userType = useAppSelector(selectUserType)
  const registrationModal = useAppSelector(selectRegistrationModal)
  const [searchParams, setSearchParams] = useSearchParams()
  const [login, { error }] = useLoginMutation()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [formData, setFormData] = useState<{ email: string; password: string }>(
    {
      email:
        invitationOrder && invitationOrder.customer_is_registered
          ? invitationOrder.customer_email
          : emailInserted || "",
      password: "",
    },
  )
  const [validated, setValidated] = useState<boolean>(false)

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget
    setFormData({ ...formData, [name]: value })
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      try {
        const authResp = await login(formData).unwrap()
        dispatch(setUser(authResp))
        if (!registrationModal && !loginModal) {
          navigate(from, { replace: true })
        } else {
          dispatch(setLoginModal(undefined))
          dispatch(setRegistrationModal(undefined))
        }
        setValidated(false)
      } catch (err) {
        dispatch(
          addToast({
            message: "Ops! Impossibile accedere al momento.",
            type: "danger",
          }),
        )
      }
      setValidated(true)
    }
  }
  return (
    <>
      <h6 className="font-33 dark-color font-bolder m-0 mt-2 mt-lg-0 mb-1">
        Accedi
      </h6>
      <p className="font-14 lightgray-txt text-center m-0 mb-4">
        Accedi al tuo account e condividi le tue esperienze con la community
      </p>
      <Form
        className={`login-form myform reg_container ${
          isInModal ? "w-100" : "w-75"
        }`}
        noValidate
        onSubmit={handleSubmit}
      >
        {userType !== undefined ? (
          <>
            <p className="lightgray-txt font-14 m-0 text-center">
              Il tuo account risulta registrato tramite {userType}.
            </p>
            <p className="lightgray-txt font-14 m-0 mb-3 text-center">
              {userType === "email"
                ? "Inserisci la tua password per accedere"
                : "Clicca il pulsante sottostante per accedere"}
            </p>
          </>
        ) : (
          <p className="lightgray-txt font-14 m-0 mb-3 text-center">
            Accedi con
          </p>
        )}

        {(userType === undefined || userType !== "email") && (
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center w-100 mb-3 gap-lg-1">
            <div id="hoauthWidgetyw0" className="hoauthWidget d-flex w-100">
              {(userType === undefined || userType === "facebook") && (
                <FacebookAuth type="Login" showName={true} />
              )}
              {(userType === undefined || userType === "google") && (
                <GoogleAuth type="Login" showName={true} />
              )}
            </div>
          </div>
        )}

        {userType === undefined && (
          <p className="lightgray-txt font-14 m-0 mb-3 text-center">oppure</p>
        )}

        {(userType === undefined || userType === "email") && (
          <>
            <Form.Group className="d-flex flex-column w-100 mb-3">
              <Form.Control
                type="text"
                className="form-control form-control-custom form-control-gray my-1"
                autoComplete="off"
                placeholder="Digita qui la tua email"
                required
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {validated && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  La password o lo username non sono corretti.
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="d-flex flex-column w-100 mb-4">
              <Form.Control
                type={showPassword ? "text" : "password"}
                className="form-control form-control-custom form-control-gray"
                autoComplete="off"
                placeholder="Password"
                required
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <span
                className="showPasswordTrigger cursor-pointer dark-color"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={["fas", "eye-slash"]} />
                ) : (
                  <FontAwesomeIcon icon={["fas", "eye"]} />
                )}
              </span>
              {validated && (
                <Form.Control.Feedback type="invalid" className="d-block">
                  La password o lo username non sono corretti.
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <p className="dark-color font-14 m-0 mb-4 text-center bg-warning bg-opacity-25 rounded px-3 py-2 font-14">
              Ti sei dimenticato la password?{" "}
              <Link
                to="/it/areariservata/recupera-credenziali"
                className="simple-blue-link orange-hover font-14 d-block"
                target="_blank"
              >
                Resettala ora
              </Link>
            </p>
            <button
              className="btn btn-animation btn-action btn-gradient-orange d-flex align-items-center justify-content-center font-semi-bolder w-100 mb-3 form"
              style={{ height: "50px" }}
              type="submit"
            >
              Accedi
            </button>
          </>
        )}

        {invitationOrder != undefined && (
          <button
            className="btn btn-outline-animation btn-outline-gradient-blue d-flex justify-content-center align-items-center font-14 w-100 my-3 p-3"
            onClick={() => dispatch(setGuestModal(true))}
            style={{ height: "50px" }}
          >
            Continua senza accedere
            <FontAwesomeIcon
              icon={["fas", "arrow-right-long"]}
              className="align-middle thin-icon font-18 ms-2"
            />
          </button>
        )}

        {userType === undefined && (
          <p className="dark-color font-14 m-0 mb-4 text-center">
            Non hai ancora un account?{" "}
            {isInModal ? (
              <span
                className="simple-blue-link cursor-pointer"
                onClick={() => dispatch(setRegistrationModal(true))}
              >
                Registrati subito qui
              </span>
            ) : (
              <Link
                to="/it/registrazione/cliente"
                className="simple-blue-link cursor-pointer"
              >
                Registrati subito qui
              </Link>
            )}
          </p>
        )}
      </Form>
      <p className="font-14 lightgray-txt text-center my-3 mx-0 mx-lg-5">
        Confermo di aver preso visione dell'
        <Link
          to="/it/informativa-privacy"
          className="simple-blue-link orange-hover"
          target="_blank"
        >
          informativa sulla privacy
        </Link>
        , della{" "}
        <Link
          to="/it/informativa-cookies"
          className="simple-blue-link orange-hover"
          target="_blank"
        >
          cookies policy
        </Link>{" "}
        e delle{" "}
        <Link
          to="/it/manleva"
          className="simple-blue-link orange-hover"
          target="_blank"
        >
          condizioni d’uso
        </Link>{" "}
        delle recensioni e acconsento all’invio della vostra newsletter.
      </p>
    </>
  )
}
