import React, { useState, useEffect } from "react"
import { Shop } from "../../../models/base/shop.interface"
import starOrange from "../../../assets/icons/star_orange.webp"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { setEsaValuesModal } from "../../modals/modalsSlice"
import { Nav, Tab } from "react-bootstrap"
import { ReviewsTabContent } from "./tabs/ReviewsTabContent"
import { SecurityRatingTabContent } from "./tabs/SecurityRatingTabContent"
import { CouponsTabContent } from "./tabs/CouponsTabContent"
import { calcTotalReviews, isPlanWithBanners } from "../../../utils/shopUtils"
import { ReviveBanner } from "../../revive_banner/ReviveBanner"
import { EsaScraperShop } from "../../../models/esaScraper/esaScraperShop.interface"
import { useGetSearchReviewsQuery } from "../../../app/services/esaScraper/reviews"
import { skipToken } from "@reduxjs/toolkit/query"
import { useGetCouponsQuery } from "../../../app/services/base/coupons"
import { RatingProgressBarContainer } from "../rating_progress_bar/RatingProgressBarContainer"
import { selectCurrentUser } from "../../auth/authSlice"

interface EShopMainContentProps {
  shop: Shop
  // reviews?: Hit<ReviewUserUnion>[]
  // productsReviews?: Hit<ReviewUserUnion>[]
  scraperShop?: EsaScraperShop
}

export const EShopMainContent: React.FC<EShopMainContentProps> = ({
  shop,
  // reviews,
  // productsReviews,
  scraperShop,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectCurrentUser)
  const areBannersActive = false // isPlanWithBanners(shop.plan)
  const totalReviews = calcTotalReviews(shop)

  const [key, setKey] = useState<string>("shopReviews")
  const [hasFeedaty, setHasFeedaty] = useState<boolean>(false)
  const noIndexSource = "feedaty"

  // Queste chiamate vengono eseguite qui per sapere se esistono o meno i dati necessari
  // Non causano doppie chiamate al server poichè la prima volta i parametri sono uguali
  // e quindi alle seconde chiamate (che avvengono nei rispettivi Tab) risponde la cache
  const { data: productReviews } = useGetSearchReviewsQuery(
    shop
      ? {
          esaDomainId: shop.id,
          query_string: undefined,
          filters: {
            enrichData: true,
            size: 10,
            orderBy: "publication_datetime-desc",
            esa_user_id: user ? user.id : undefined,
          },
          review_prod: true,
          searchAfter: undefined,
        }
      : skipToken,
  )

  // TODO: check this
  const { data: noIndexReviews } = useGetSearchReviewsQuery(
    shop
      ? {
          esaDomainId: shop.id,
          query_string: undefined,
          filters: {
            enrichData: true,
            size: 10,
            orderBy: "publication_datetime-desc",
            esa_user_id: undefined,
            import_source: noIndexSource,
          },
          review_prod: false,
          searchAfter: undefined,
        }
      : skipToken,
  )

  const { data: couponsWithShops } = useGetCouponsQuery({
    eshop_id: shop.id,
    page: 1,
    perPage: 10,
  })

  // useEffect(() => {
  //   if (noIndexReviews) {
  //     setHasFeedaty(noIndexReviews.hits?.hits ? true : false)
  //   }
  // }, [noIndexReviews])

  return (
    <div className="card w-100 h-100 mb-4">
      <div className="card-body d-flex flex-column p-3 p-lg-4">
        <div
          className={`d-flex flex-row align-items-center gap-2 ${
            totalReviews > 0 ? "mb-4" : ""
          }`}
        >
          <h2 className="blue-card-title font-23 m-0 line-clamp break-all">
            Recensioni {shop.domain}
          </h2>
          <img src={starOrange} alt="star" style={{ width: "24px" }} />
          <div className="rating-number-eshop font-bolder font-23">
            {shop.rating}
          </div>
        </div>
        {totalReviews > 0 && (
          <>
            <RatingProgressBarContainer shop={shop} />
            <div className="d-flex flex-column flex-lg-row justify-content-start justify-content-lg-end align-items-start align-items-lg-center">
              {/* <div className="mt-2 mt-lg-0 mb-3 mb-lg-2 font-lighter dark-color font-14">
                Basato su
                <span className="font-bolder"> {totalReviews} </span>
                Recensioni di cui
                <span className="font-semi-bolder simple-orange-txt">
                  {" "}
                  {shop.nRecensioniImport}{" "}
                </span>
                importate
              </div> */}
              {/* <div className="mt-2 mt-lg-0 mb-3 mb-lg-2">
                <a
                  className="simple-blue-link cursor-pointer"
                  onClick={() => dispatch(setEsaValuesModal(shop))}
                >
                  Mostra dettagli recensioni eShoppingAdvisor
                </a>
              </div> */}
            </div>
          </>
        )}

        {areBannersActive && (
          <div className="row py-2 mx-auto">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-none d-md-block"
              id="zone-id-13"
            >
              <ReviveBanner zoneId="16" shop={scraperShop} />
            </div>
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-md-none text-center"
              id="zone-id-13"
            >
              <ReviveBanner zoneId="18" shop={scraperShop} />
            </div>
          </div>
        )}

        <hr className="border border-dark" />

        <Tab.Container activeKey={key} onSelect={(k) => setKey(k ? k : "")}>
          <nav>
            <Nav
              variant="underline"
              className="custom-tabs flex-row overflow-x-auto flex-nowrap mb-3"
            >
              <Nav.Link eventKey="shopReviews" className="font-14 p-1">
                Recensioni shop
              </Nav.Link>
              {shop.plan == "company" &&
                productReviews &&
                productReviews.hits.hits.length > 0 && (
                  <Nav.Link eventKey="productsReviews" className="font-14 p-1">
                    Recensioni prodotti
                  </Nav.Link>
                )}
              {shop.plan != "ibrido" &&
                couponsWithShops &&
                couponsWithShops.items.length > 0 && (
                  <Nav.Link eventKey="coupons" className="font-14 p-1">
                    Coupon Sconto
                  </Nav.Link>
                )}
              <Nav.Link eventKey="securityRating" className="font-14 p-1">
                Security Rating
              </Nav.Link>
              {hasFeedaty && (
                <Nav.Link
                  href={`/vetrina/feedaty/${shop.domain}/`}
                  className="font-14 p-1"
                >
                  Recensioni Feedaty
                </Nav.Link>
              )}
            </Nav>
          </nav>
          <Tab.Content>
            {/* {reviews && reviews.length > 0 && ( */}
            <ReviewsTabContent
              shop={shop}
              isProductTab={false}
              scraperShop={scraperShop}
            />
            {/* )} */}
            {shop.plan == "company" &&
              productReviews &&
              productReviews.hits.hits.length > 0 && (
                <ReviewsTabContent
                  shop={shop}
                  isProductTab={true}
                  scraperShop={scraperShop}
                />
              )}
            {shop.plan != "ibrido" &&
              couponsWithShops &&
              couponsWithShops.items.length > 0 && (
                <CouponsTabContent shop={shop} />
              )}
            <SecurityRatingTabContent shop={shop} activeKey={key} />
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  )
}
