import React from "react"
import logoWhite from "../../assets/images/logo-white.png"

export const HeaderSimple: React.FC = () => {
  return (
    <>
      <div className={"header mb-3"}>
        <div className="container py-2">
          <div className="row align-items-center justify-content-center">
            <div className="col-sm-12 col-lg-auto text-center">
              <picture className="logo-header">
                <img
                  src={logoWhite}
                  alt="eShoppingAdvisor"
                  className="img-fluid my-1"
                  loading="lazy"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
