import { Fragment, ChangeEvent } from "react"
import { Form } from "react-bootstrap"
import star from "../../assets/icons/star.webp"
import React from "react"
import { formatNumber } from "../../utils/stringUtils"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  selectSendReviewFormData,
  selectSendReviewFormErrors,
  updateProductScore,
  updateShopScores,
} from "../../pages/send_review/sendReviewSlice"

interface InputRatingStarsProps {
  inputName: string
  customStarClass?: string
  customAverageRatingClass?: string
  customContainerClass?: string
  reviewType: "shop" | "product"
  forceNotShowError?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const InputRatingStars: React.FC<InputRatingStarsProps> = ({
  inputName,
  customStarClass,
  customAverageRatingClass,
  reviewType,
  forceNotShowError,
  customContainerClass = "justify-content-left",
  onChange,
}) => {
  const dispatch = useAppDispatch()
  const formData = useAppSelector(selectSendReviewFormData)
  const errors = useAppSelector(selectSendReviewFormErrors)

  return (
    <Form.Group className="d-flex flex-column">
      <div
        className={`${customContainerClass} d-flex align-items-center rating-eshop flex-row-reverse`}
      >
        <span
          className={`d-none d-lg-block rating-number-eshop fieldRating font-bolder ms-2 min-width-40 text-end ${
            customAverageRatingClass ? customAverageRatingClass : ""
          }`}
        >
          {reviewType === "shop"
            ? formatNumber(formData.shop.scores[inputName], 1)
            : formData.products[inputName]?.score
            ? formatNumber(formData.products[inputName]?.score, 1)
            : formatNumber(0, 1)}
        </span>
        {[5, 4, 3, 2, 1].map((number) => (
          <Fragment key={inputName + number}>
            <Form.Control
              id={inputName + number}
              name={inputName}
              type="radio"
              className="btn-check ratingParameter"
              autoComplete="off"
              required={inputName == "Assistenza clienti" ? false : true}
              value={number}
              checked={
                reviewType === "shop"
                  ? formData.shop.scores[inputName] == number
                  : formData.products[inputName]?.score === number
              }
              onClick={(e) => {
                const { name, value } = e.currentTarget
                if (reviewType === "shop") {
                  dispatch(updateShopScores({ name, value }))
                } else {
                  dispatch(updateProductScore({ name, value }))
                }
              }}
              onChange={onChange}
              isInvalid={!!errors[inputName]}
            />
            <Form.Label
              htmlFor={inputName + number}
              className={`d-inline-flex justify-content-center align-items-center star-container me-1 star-big-container mb-0 ${
                customStarClass ? customStarClass : ""
              }`}
            >
              <div className="star-progress-container">
                <div className={`star-progress star-${number}`}></div>
              </div>
              <img src={star} alt={`punteggio recensioni ${number}`} />
            </Form.Label>
          </Fragment>
        ))}
      </div>
      {forceNotShowError != true && errors[inputName] && (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          {errors[inputName]}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
