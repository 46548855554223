import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback } from "react"
import { Accordion, Card } from "react-bootstrap"
import { ReviewFieldInput } from "./ReviewFieldInput"
import { useAppDispatch } from "../../app/hooks"
import { confirmProductReview } from "../../pages/send_review/sendReviewSlice"
import { ReviewFieldTextarea } from "./ReviewFieldTextarea"
import { ReviewFieldCheck } from "./ReviewFieldCheck"
import { InvitationOrderProduct } from "../../models/base/invitationOrder.interface"
import productPlaceholder from "../../assets/images/thumb_placeholder.png"
import { Shop } from "../../models/base/shop.interface"
import useWindowSize from "../../utils/useWindowSize"
import { useSendTraceEventMutation } from "../../app/services/base/tracer"
import { debounce } from "lodash"
import {
  updateProductReview,
  updateShopReview,
} from "../../pages/send_review/sendReviewSlice"

interface ProductReviewItemProps {
  index: number
  product: InvitationOrderProduct
  isReviewed: boolean
  setKeys: (key: string, confirmAction?: boolean) => void
  shop?: Shop
  orderId?: number
}
export const ProductReviewItem: React.FC<ProductReviewItemProps> = ({
  index,
  product,
  isReviewed,
  setKeys,
  shop,
  orderId,
}) => {
  const [sendTraceEvent] = useSendTraceEventMutation()
  const TrackReviewsInputField = useCallback(
    debounce((e, orderId) => {
      if (orderId) {
        sendTraceEvent({
          event: "product_review_text_input",
          data: {
            field: e.target.name,
            value: e.target.value,
            id: orderId,
            type: "invitation",
          },
        })
      }
    }, 700),
    [],
  )
  const dispatch = useAppDispatch()

  function confirmReviewProduct(product: InvitationOrderProduct) {
    const stepIndicator = document.querySelector(
      ".step-indicator-container",
    ) as HTMLElement
    let scrollValue = stepIndicator?.offsetTop

    dispatch(confirmProductReview(product))

    setKeys(product.id, true)

    // Use requestAnimationFrame to ensure the layout is updated before scrolling
    requestAnimationFrame(() => {
      window.scrollTo({
        top: scrollValue,
        behavior: "smooth",
      })
    })
  }

  const windowSize = useWindowSize()
  const isMobile = windowSize.width && windowSize.width <= 768

  return (
    <Card className={`w-100 mb-3 p-2 ${isReviewed ? "orange-border" : ""}`}>
      <Accordion.Item eventKey={product.id.toString()}>
        <Accordion.Header
          className="d-flex flex-row align-items-center justify-content-start w-100 cursor-pointer productReviewTrigger" //accordion-button
          onClick={() => setKeys(product.id.toString())}
        >
          {product.img_prod && (
            <div
              className="d-flex align-items-center ms-1 me-3 overflow-hidden"
              style={{ width: "70px", height: "70px" }}
            >
              <img
                src={product.img_prod}
                alt={product.name}
                width="100%"
                loading="lazy"
                onError={(e) => (e.currentTarget.src = productPlaceholder)}
              />
            </div>
          )}
          <div
            className="d-flex flex-column align-items-start justify-content-center step-header gap-1 me-1"
            style={{ maxWidth: "375px" }}
          >
            <h6 className="font-16 dark-color font-bolder step-header-title m-0">
              {product.name}
            </h6>
            {shop && <p className="lightgray-txt m-0">{shop.domain}</p>}
            {isReviewed && (
              <span className="orange-color font-semi-bolder font-14 d-block d-lg-none">
                <FontAwesomeIcon icon={["fas", "check"]} className="me-1" />
                Recensito
              </span>
            )}
          </div>
          {isReviewed && (
            <div className="d-flex flex-column align-items-start justify-content-center step-header gap-1 me-1">
              <span
                className="orange-color font-semi-bolder font-14 d-none d-lg-block"
                style={{ position: "absolute", right: "50px" }}
              >
                <FontAwesomeIcon icon={["fas", "check"]} className="me-1" />
                Recensito
              </span>
            </div>
          )}
        </Accordion.Header>
        <Accordion.Body className="productAccordionItem border-top mt-3 p-0">
          <div className="d-flex flex-column align-items-center justify-content-center my-4">
            <h4 className="font-16 lg-font-23 dark-color font-bolder m-0 mb-3">
              Valutazione Prodotto
            </h4>
            <div className="d-flex flex-column">
              <ReviewFieldInput
                index={index}
                infoText=""
                inputName={product.id.toString()}
                reviewType="product"
                forceNotShowError={isMobile ? true : undefined}
                mainRating={false}
                onChange={(e) => {
                  if (orderId) {
                    sendTraceEvent({
                      event: "product_review_rating_input",
                      data: {
                        field: product.id.toString(),
                        value: e.target.value,
                        type: "invitation",
                        id: orderId,
                      },
                    })
                  }
                }}
              />
            </div>
          </div>
          <div className="w-100 p-3">
            <div className="d-flex flex-column align-items-start justify-content-center">
              <ReviewFieldTextarea
                inputName={product.id.toString()}
                placeholder="Raccontaci la tua esperienza..."
                minCharacters={3}
                maxCharacters={600}
                onEvent={(e) => {
                  TrackReviewsInputField(e, orderId)
                  const { name, value } = e.currentTarget
                  if (name != "title" && name != "description") {
                    dispatch(updateProductReview({ name, value }))
                  } else {
                    dispatch(updateShopReview({ name, value }))
                  }
                }}
              />

              <div className="row align-items-center">
                {shop &&
                  shop.eshopData.structured_data.anonymous_reviews &&
                  shop.eshopData.structured_data.anonymous_reviews == 1 && (
                    <div className="d-flex flex-column mb-4">
                      <ReviewFieldCheck
                        inputName={"isAnonymous"}
                        infoText
                        onEvent={(e) => {
                          if (orderId) {
                            sendTraceEvent({
                              event: "product_review_check_input",
                              data: {
                                field: product.id.toString(),
                                value: {"is_anonymous": e.target.checked},
                                type: "invitation",
                                id: orderId,
                              },
                            })
                          }
                        }}
                      />
                    </div>
                  )}
                <div className={`col-12 d-none d-lg-block`}>
                  <div className="d-flex align-items-center justify-content-end">
                    <span
                      className="btn btn-secondary-orange px-3 py-2 font-14 border-radius-25 reviewProdConfirm"
                      onClick={() => {
                        confirmReviewProduct(product)
                        if (orderId) {
                          sendTraceEvent({
                            event: "product_review_submit",
                            data: {
                              field: product.id.toString(),
                              value: new Date()
                                .toISOString()
                                .replace("T", " ")
                                .slice(0, 19),
                              type: "invitation",
                              id: orderId,
                            },
                          })
                        }
                      }}
                    >
                      Conferma recensione
                    </span>
                  </div>
                </div>
                <div className="col-12 d-flex d-lg-none">
                  <div className="d-flex align-items-center justify-content-end w-100">
                    <span
                      className="btn btn-secondary-orange px-3 py-2 font-14 border-radius-25 reviewProdConfirm w-100 mt-3"
                      onClick={() => {
                        confirmReviewProduct(product)
                        if (orderId) {
                          sendTraceEvent({
                            event: "product_review_submit",
                            data: {
                              field: product.id.toString(),
                              value: new Date()
                                .toISOString()
                                .replace("T", " ")
                                .slice(0, 19),
                              type: "invitation",
                              id: orderId,
                            },
                          })
                        }
                      }}
                    >
                      Conferma recensione
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Card>
  )
}
